<template>
  <main-body-section class="body-h-fix">
    <base-panel title="انتخاب زمین" :rightPanel="false" class="text-center">
      <template #default>
        <vue-element-loading
          :active="farmsNameListLoadingState"
          text=""
          spinner="bar-fade-scale"
          color="var(--secondary)"
        />
        <farms-name-list
          :farmsNameList="getFarmsNameList"
          :farmersNameList="getFarmsNameList"
          :displayReturnButton="!isFarmer && !isUserIsFarmer"
          :selectFarmLoading="selectFarmLoading"
          @selectFarm="selectFarm"
          @backToFarmer="backToFarmer"
        ></farms-name-list>
      </template>
    </base-panel>
    <switch-map @switchMap="switchLayer" :is-satelite="isSatelite"></switch-map>
    <template #map>
      <div class="-m-anti-card" id="map" @click="changeComponent(null)"/>
    </template>
    <div class="search-box">
      <geocode-search @selectedGeocode="geocodeFind"></geocode-search>
    </div>

    <div style="overflow: hidden">
      <notifications group="dashboard" position="top left" :duration="5000"/>
      <CModal
        title="راهنمای رنگ نقشه"
        :show.sync="legendModal"
        centered
        id="chartModal"
        :fade="true"
        class="d-none d-md-block"
      >
        <div
          class="row justify-content-center align-items-center"
          style="background: #f1f1f1"
        >
          <div class="justify-selft-center" style="background: #f1f1f1">
            <img :src="getSrc(indicatorHelpImage)" alt="" width="400rem"/>
          </div>
        </div>
      </CModal>
      <CModal
        title="نمودار تغییرات"
        :show.sync="showIndicatorStateModel"
        size="xl"
        centered
        id="chartModal"
        :fade="true"
      >
        <line-chart
          :datesList="indicatorStateDate"
          :dataList="indicatorStateData"
          :title="indicatorStateTitle"
        ></line-chart>
      </CModal>
      <CModal
        title="هشدار"
        :show.sync="weatherModal"
        size="lg"
        centered
        color="warning"
        :fade="true"
      >
        <p v-for="(item, index) in weatherMessage" :key="index">
          {{ item }}
        </p>
        <template #footer>
          <CButton @click="weatherModal = false" color="warning"
          >متوجه شدم
          </CButton
          >
        </template>
      </CModal>

      <transition name="fade">
        <base-panel
          :rightPanel="true"
          v-if="shwoIndexInfo && !toggleToFarmInfoPanel && selectedFarm"
          title="اطلاعات شاخص"
        >
          <template #default>
            <index-info
              :layerSelectedId="indicatorId"
              :avaliabledateTimesList="persianMonthes"
              :farmSelected="selectedFarm"
              @changeLayerImage="addImageLayer"
              @showLayerState="showStateLayerInfo"
              @showHelpColor="showHelpColorInfo"
            ></index-info>
          </template>
        </base-panel>
      </transition>
      <transition name="fade">
        <base-panel
          :rightPanel="true"
          v-if="selectedFarm && toggleToFarmInfoPanel"
          title="مشخصات زمین"
        >
          <template #default>
            <farm-info
              :farmInfo="selectedFarm"
              :area="selectedFarmArea"
            ></farm-info>
          </template>
        </base-panel>
      </transition>
      <VueModal
        :title="DietPlanAndAdviceModelTitle"
        v-model="DietPlanAndAdviceModelState"
        wrapper-class="animate__animated animate__faster"
        modal-class="fullscreen-modal"
        in-class="animate__fadeIn"
        out-class="animate__fadeOut"
      >
        <diet-plan
          v-if="DietPlanAndAdvice === 'dietPlan'"
          :farmId="selectedFarm.id"
        >
        </diet-plan>
        <advice v-if="DietPlanAndAdvice === 'advice'" :farmId="selectedFarm.id">
        </advice>
      </VueModal>

      <div
        class="focusWarper"
        v-if="currentCmp === 'indexes'"
        @click="changeComponent(null)"
      ></div>
      <div class="indexMenu" v-if="currentCmp">
        <transition name="fade">
          <choose-index
            v-if="currentCmp === 'indexes'"
            :farmSelectedId="selectedFarm.id"
            @changeComponent="changeComponent"
            @loadIndicatorForm="getMonthWithCultivationsDate"
          ></choose-index>
        </transition>
      </div>

      <menu-btn
        :isFarmSelected="selectedFarm"
        @getAvaliableMonth="getNumOfAvaliableMonthes(kashtDatePersian)"
        @changeComponent="changeComponent"
        @loadAdviceDietPlan="loadAdviceDietPlan"
        :coordinate="polygonCenter"
      ></menu-btn>
    </div>
  </main-body-section>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { ZoomControl } from "mapbox-gl-controls";
import GeocodeSearch from "../components/map/GeocodeSearch.vue";

import BasePanel from "../components/map/BasePanel.vue";
import FarmsNameList from "../components/map/FarmsNameList.vue";
import SwitchMap from "../components/map/SwitchMap.vue";
import MenuBtn from "../components/map/menu/MenuBtn.vue";
import ChooseIndex from "../components/map/menu/ChooseIndex.vue";
import IndexInfo from "../components/map/IndexInfo.vue";
import LineChart from "../components/map/LineChart.vue";
import DietPlan from "../containers/DietPlan.vue";
import Advice from "../containers/Advice.vue";

import axios from "axios";
import polyGonStructure from "../store/data/polygonFeature.json";
import * as turf from "@turf/turf";
import {persianToMiladiService} from "../services/persiantomiladi.service";
import {numOfAvaliableMonthService} from "../services/getNumOfAvaliableMonth.service";
import {calculateBboxService} from "../services/calculateBbox.service";
import {formatPolygonService} from "../services/formatPolygon.service";
import {apiUrlRoot} from "../constants/config";
import * as moment from "jalali-moment";

import FarmInfo from "../components/map/FarmInfo.vue";
import MainBodySection from "../components/mainBodySection.vue";

// const instance = axios.create({
//   baseURL: "https://api.openweathermap.org/data/2.5/",
// });
export default {
  name: "DashboardForm",
  components: {
    MainBodySection,
    BasePanel,
    FarmsNameList,
    SwitchMap,
    MenuBtn,
    ChooseIndex,
    IndexInfo,
    LineChart,
    FarmInfo,
    DietPlan,
    Advice,
    GeocodeSearch,
  },
  data() {
    return {
      farmsSource: [],
      currentCmp: null,
      isSatelite: true,
      selectFarmLoading: false,
      map: null,
      isRightPanelVisible: false,
      isLeftPanelVisible: true,
      farmersSource: [],
      isFarmer: false,
      selectedFarm: null,
      farmsNameList: [],
      avaliableDays: [],
      avaliableDaysPersian: [],
      kashtDatePersian: null,
      numOfAvaliableMonth: null,
      todayDatePersian: null,
      request: {
        layerId: null,
        date: null,
        box: null,
        polygon: [],
        formatedPolygon: null,
      },
      noData: false,
      selectedFarmArea: null,
      polygonCenter: null,
      toggleToFarmInfoPanel: true,
      legendModal: false,
      location: null,
      geocodeSuggestions: [],
      weatherModal: false,
      weatherMessage: [],
      weather: null,
      persianMonthes: [],
      indicatorId: 0,
      shwoIndexInfo: false,
      indicatorHelpImage: "",
      showIndicatorStateModel: false,
      indicatorStateDate: [],
      indicatorStateData: [],
      indicatorStateTitle: "",
      farmsNameListLoadingState: false,
      DietPlanAndAdvice: "",
      DietPlanAndAdviceModelState: false,
      DietPlanAndAdviceModelTitle: "",

      farmImage: null,
      window: document.window,
    };
  },
  watch: {
    // polygonCenter() {
    //   this.getCurrentWeather();
    // },
  },
  created() {
    this.getSentinelToken();
  },
  computed: {
    ...mapGetters({
      sentinelToken: "sentinel/gteToken",
    }),
    ...mapState({
      isUserIsFarmer: function (state) {
        return (
          state.login.type.toLowerCase() == "farmer" ||
          state.login.type.toLowerCase() == "advicer" ||
          state.login.type.toLowerCase() == "farmuser" ||
          state.login.type.toLowerCase() == "legalentity"
        );
      },
      isUserHaveFinancialAccount: function (state) {
        return (
          state.login.type.toLowerCase() == "farmer" ||
          state.login.type.toLowerCase() == "legalentity" ||
          state.login.type.toLowerCase() == "agency" ||
          state.login.type.toLowerCase() == "advicer"
        );
      },
    }),
    getFarmsNameList() {
      return this.farmsNameList;
    },
    getFarmersNameList() {
      return this.farmersSource;
    },
    getGeocodeList() {
      return this.geocodeSuggestions;
    },
  },
  methods: {
    ...mapActions({
      getSentinelToken: "sentinel/getToken",
    }),
    ...mapActions("financialUser", ["CheckAndCreateAccount"]),
    ...mapActions("farm", ["GetsTextValue", "Get"]),
    ...mapActions("dashboard", [
      "GetsFarmerTextValue",
      "GetsLegalEntityTextValue",
      "GetMessage",
      "GetIndicatorDateTimes",
      "CheckMapImage",
    ]),

    // async getCurrentWeather() {
    //   await instance
    //     .get(
    //       `onecall?lat=${this.polygonCenter[1]}&lon=${this.polygonCenter[0]}&exclude=hourly,minutely&units=metric&lang=fa&appid=eed47d0ee3d09e1106adffc54a39dd5c`
    //     )
    //     .then((res) => {
    //       this.weather = res.data;
    //     })
    //     .catch(() => {
    //       return;
    //     });
    //   let condition = {
    //     temp: null,
    //     maxTemp: null,
    //     minTemp: null,
    //     humidity: null,
    //     windSpeed: this.weather.current.wind_speed,
    //     chanceOfRine: this.weather.daily[0].pop,
    //     visibility: this.weather.current.visibility,
    //   };
    //   var result = await this.GetMessage({condition});
    //   if (result) {
    //     if (result.messages.length > 0) {
    //       this.weatherMessage = result.messages;
    //       this.weatherModal = true;
    //     }
    //   } else {
    //     this.weatherModal = false;
    //   }
    // },
    async getocodeLocate() {
      await axios
        .get(
          `https://nominatim.openstreetmap.org/search.php?q=${this.location}&format=jsonv2`
        )
        .then((res) => {
          this.geocodeSuggestions = res.data;
        })
        .catch(() => {
          return;
        });
    },
    calculateBbox(polygon) {
      this.request.box = calculateBboxService.calculateBox(polygon);
    },
    getMonthWithCultivationsDate(indicatorId) {
      this.indicatorId = indicatorId;
      this.toggleToFarmInfoPanel = false;
      this.shwoIndexInfo = true;
    },
    getValidMonth(month) {
      return month.length == 1 ? `0${month}` : month;
    },
    addImageLayer(imageUrl, helpImageName) {
      this.indicatorHelpImage = helpImageName;
      if (this.map.getSource("indexImage")) {
        if (this.map.getLayer("indexImage-layer")) {
          this.map.removeLayer("indexImage-layer");
        }
        this.map.removeSource("indexImage");
      }
      this.map.addSource("indexImage", {
        type: "image",
        url: imageUrl,
        coordinates: [
          [this.request.box[0], this.request.box[3]],
          [this.request.box[2], this.request.box[3]],
          [this.request.box[2], this.request.box[1]],
          [this.request.box[0], this.request.box[1]],
        ],
      });
      this.map.addLayer({
        id: "indexImage-layer",
        type: "raster",
        source: "indexImage",
        paint: {
          "raster-fade-duration": 0,
        },
      });
    },
    showStateLayerInfo(dateList, dataList, title) {
      this.indicatorStateDate = dateList;
      this.indicatorStateData = dataList;
      this.indicatorStateTitle = title;
      this.showIndicatorStateModel = true;
    },
    showHelpColorInfo() {
      if(this.indicatorHelpImage){
        this.legendModal = true;
      }else{
        this.$notify({
              group: "dashboard",
              title: "پیام سیستم",
              text: `یک شاخص را انتخاب کنید <br /><br />`,
              type: "info",
            });
      }
    },
    convertNumbers2English: (str) =>
      str.replace(
        /([٠١٢٣٤٥٦٧٨٩])|([۰۱۲۳۴۵۶۷۸۹])/g,
        // eslint-disable-next-line no-unused-vars
        (m, $1, $2) => m.charCodeAt(0) - ($1 ? 1632 : 1776)
      ),

    async getNumOfAvaliableMonthes(kashtDate) {
      const kashtDateMiladi = persianToMiladiService.persianToMiladi(kashtDate);
      this.numOfAvaliableMonth =
        numOfAvaliableMonthService.calculateNumOfMonth(kashtDateMiladi);
    },

    changeComponent(cmp) {
      this.currentCmp = cmp;
    },
    loadAdviceDietPlan(cmp) {
      this.DietPlanAndAdvice = cmp;
      this.DietPlanAndAdviceModelTitle =
        cmp == "dietPlan" ? "برنامه های غذایی زمین" : "توصیه های کشاورزی زمین";
      if (this.selectedFarm) this.DietPlanAndAdviceModelState = true;
      else
        this.$notify({
          title: "پیام سیستم",
          text: `ابتدا زمین را انتخاب کنید`,
          type: "error",
        });
    },
    switchLayer(layer) {
      this.map.setStyle("mapbox://styles/" + layer);
      this.isSatelite = !this.isSatelite;
    },
    async fetchFarms(farmerId, type) {
      this.farmsNameListLoadingState = true;
      if (!farmerId) {
        var noFarmerResult = await this.GetsTextValue({
          farmerType: null,
          farmerId: null,
        });
      } else {
        var farmerResult = await this.GetsTextValue({
          farmerType: type,
          farmerId: farmerId,
        });
      }
      let result = noFarmerResult ?? farmerResult;
      if (result) {
        this.farmsSource = [];
        result.filter((item) => {
          let farm = {
            text: item.text,
            value: item.value,
          };
          this.farmsSource.push(farm);
        });
      }
      this.farmsNameList.splice(0, this.farmsNameList.length);
      this.farmsNameList = [];
      this.farmsNameList = this.farmsSource;
      this.isFarmer = false;
      this.farmsNameListLoadingState = false;
    },
    async fetchList() {
      this.farmsNameListLoadingState = true;
      if (this.isUserIsFarmer) {
        this.fetchFarms(null);
        return;
      }
      var result = await this.GetsFarmerTextValue({});
      this.farmersSource = [];
      if (result)
        result.filter((item) => {
          let farm = {
            text: item.text,
            value: item.value,
            type: item.type,
          };
          this.farmersSource.push(farm);
        });
      this.farmsNameList.splice(0, this.farmsNameList.length);
      this.farmsNameList = this.farmersSource;
      this.isFarmer = true;
      this.farmsNameListLoadingState = false;
    },
    async fetchFarm(id) {
      var result = await this.Get({id: id});
      this.selectedFarm = result;
    },
    backToFarmer() {
      this.isFarmer = true;
      this.fetchList();
    },
    addPolygonLayer() {
      let geojason = {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [this.request.polygon],
        },
      };
      // Add a data source containing GeoJSON data.
      if (this.map.getSource("maine")) {
        if (this.map.getLayer("maine")) {
          this.map.removeLayer("maine");
          // this.map.removeLayer("outline");
        }
        this.map.removeSource("maine");
      }
      if (this.map.getSource("indexImage")) {
        if (this.map.getLayer("indexImage-layer")) {
          this.map.removeLayer("indexImage-layer");
        }
        this.map.removeSource("indexImage");
      }
      this.map.addSource("maine", {
        type: "geojson",
        data: geojason,
      });

      // Add a new layer to visualize the polygon.
      this.map.addLayer({
        id: "maine",
        type: "fill",
        source: "maine", // reference the data source
        layout: {},
        paint: {
          "fill-color": "#0080ff", // blue color fill
          "fill-opacity": 0.5,
        },
      });
    },

    async loadFarmMap(cordinates, farmId) {
      const newArray = [cordinates];
      polyGonStructure.features[0].geometry.coordinates = newArray;

      let farmArea = turf.area(polyGonStructure.features[0].geometry);
      this.selectedFarmArea = Intl.NumberFormat("fa").format(
        (farmArea / 10000).toFixed(2)
      );
      this.request.polygon = cordinates;
      let polygon = turf.polygon([this.request.polygon]);
      let center = turf.centerOfMass(polygon);
      this.polygonCenter = center["geometry"]["coordinates"];
      this.request.formatedPolygon = formatPolygonService.polygonreFormat(
        this.request.polygon
      );
      this.calculateBbox(this.request.polygon);

      this.addPolygonLayer();
      this.map.fitBounds(this.request.box, {
        padding: 260,
        animate: true,
        maxZoom: 18,
        duration: 5000,
      });
      this.map.on("moveend", async function (e) {
        if (farmId > 0) {
          let image = e.target._canvas.toDataURL();
          await window.func.checkFarmImage(farmId, image);
        }
      });
    },
    geocodeFind(coords) {
      this.map.fitBounds([
        [coords[2], coords[0]], // southwestern corner of the bounds
        [coords[3], coords[1]], // northeastern corner of the bounds
      ]);
    },
    async selectFarm(item) {
      this.selectFarmLoading = true;
      this.toggleToFarmInfoPanel = true;
      if (this.isFarmer) {
        this.fetchFarms(item.value, item.type);
      } else {
        var result = await this.Get({id: item.value});
        this.kashtDatePersian = moment(result.cultivationsDate)
          .locale("fa")
          .format("YYYY-MM-D");
        result.kashtDatePersian = this.kashtDatePersian;
        this.selectedFarm = result;
        this.loadFarmMap(this.selectedFarm.cordinates, item.value);
        await this.getUserDateTimes();
      }
      this.selectFarmLoading = false;
    },

    getSrc(fielName) {
      if (fielName) {
        return `${apiUrlRoot}/Indicator/HelpImage/${fielName}`;
      }
    },
    async getUserDateTimes() {
      this.persianMonthes = [];
      let dateTimeResult = await this.GetIndicatorDateTimes({
        farmId: this.selectedFarm.id,
      });
      this.persianMonthes = dateTimeResult.data;
    },
    async CheckAndCreateAccountState() {
      let result = await this.CheckAndCreateAccount();
      if (!result.succeeded) {
        this.$notify({
          title: "پیام سیستم",
          text: `کیف پول شما مشکل دارد با واحد پشتیانی تماس بگیرید`,
          type: "error",
        });
        return false;
      }
      return true;
    },
    async checkFarmImage(farmId, farmImage) {
      let formData = new FormData();
      formData.append("FarmId", farmId);
      formData.append("Image", farmImage);
      let result = await this.CheckMapImage({info: formData});
      // console.log("Aaa", result);
    },
  },
  updated() {
    if (this.farmersSource.length === 0 || this.farmsNameList === 0)
      this.fetchList();
  },
  mounted() {
    this.selectedFarm = null;
    this.fetchList();
    mapboxgl.accessToken =
      "pk.eyJ1IjoiYmlvY2hpYXBwIiwiYSI6ImNsMzhiOHdyaTA1OXYzZG13aGI5bXc0Y2YifQ.KK2ncJJG5hJWECxJ9jQwCA";
    if (mapboxgl.getRTLTextPluginStatus() === "unavailable") {
      mapboxgl.setRTLTextPlugin(
        "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
        null,
        true // Lazy load the plugin
      );
    }
    this.map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/biochiapp/cl4chu50q000314qsi4ng5wj1",
      //center: [59.55679711007316, 36.316138357670575], // starting position [lng, lat]
      center: [53.12609431323281, 33.59332186046887], // starting position [lng, lat]
      zoom: 5,
      showZoom: true,
      attributionControl: false,
      preserveDrawingBuffer: true,
    });
    this.map.addControl(new ZoomControl(), "bottom-left");
    this.map.on("load", () => {});
    this.map.on("moveend zoomend", function () {
      console.log("moveend || zoomend");
    });
    this.map.on("draw.create", this.addPolygonLayer);
    new Intl.DateTimeFormat("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    }).format(new Date());
    if (this.isUserIsFarmer && this.isUserHaveFinancialAccount) {
      this.CheckAndCreateAccountState();
    }
    window.func = this;
  },
};
</script>
<style>
.fullscreen-modal {
  width: 90%;
  max-width: 90%;
  height: 95dvh;
  top: 0;
  display: flex;
  flex-direction: column;
  margin: auto;
}
.fullscreen-modal .vm-titlebar {
  flex-shrink: 0;
}
.fullscreen-modal .vm-content {
  padding: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}


.vue-notification {
  padding: 10px;
  margin: 0 5px 5px;

  font-size: 12px;

  color: #ffffff;
  background: #44a4fc;
  border-left: 5px solid #187fe7;
}

.warn {
  background: #ffb648;
  border-left-color: #f48a06;
}

.error {
  background: #e54d42;
  border-left-color: #b82e24;
}

.success {
  background: #68cd86;
  border-left-color: #42a85f;
}
.ads-wrapper{
  top: 60px !important;
  max-width: 22rem;
}
</style>
<style scoped>
body {
  margin: 0;
  padding: 0;
}

.focusWarper {
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  inset: -20px;
  backdrop-filter: blur(5px);
  z-index: 2;
}

.chooseDateWarper {
  background: transparent;
  position: absolute;
  top: 50%;
  margin-top: -15rem;
  left: 50%;
  margin-left: -10rem;
  width: 20rem;
  padding: 0;
  z-index: 1;
}

.calculation-box {
  height: 75px;
  width: 150px;
  position: absolute;
  bottom: 40px;
  left: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px;
  text-align: center;
  z-index: 1;
}

.indexMenu {
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -5rem;
  margin-left: -10rem;
  width: 20rem;
  padding: 0;
  z-index: 3;
}

.search-box {
  position: absolute;
  width: 22rem;
  left: 0;
  z-index: 2;
}

</style>
